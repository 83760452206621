import TYPES from '@/types';
import Vue from 'vue';

import {
  maxLengthGoalNameRule,
  maxValueRule,
  minValueRule,
  requiredRule,
} from '@/vue-app/utils/form-rules';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import CalculateCustomGoalMaximumInitialAmountQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-maximum-initial-amount-query';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  InvestorProfileStateManager,
} from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import {
  CustomGoalMaximumInitialAmountCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-initial-amount-dto';

export default class CustomDefinePlanViewModel {
  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MAXIMUM_INITIAL_AMOUNT_QUERY)
  private readonly calculate_maximum_initial_amount_query!:
    CalculateCustomGoalMaximumInitialAmountQuery;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
  readonly investor_profile_state_manager!: InvestorProfileStateManager;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.custom.define-plan';

  readonly view: Vue;

  readonly pocket_interest_rate = 0.055;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  investor_profile = this.investor_profile_state_manager.state;

  inputs = {
    desired_amount: '0.0',
    initial_amount: '0.0',
  }

  maximum_initial_amount = 0;

  timer?: NodeJS.Timer;

  is_valid_form = false;

  constructor(view: any) {
    this.view = view;
  }

  get is_continue_btn_disabled() {
    return this.investor_goal_state.is_loading || !this.is_valid_form;
  }

  inputs_rules = {
    desired_amount: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    initial_balance: [requiredRule,
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''),
        '$0.00 MXN',
        0),
      (value: string) => maxValueRule(
        value.replace(/[^0-9.-]/g, ''),
        `$${this.maximum_initial_amount} MXN`,
        this.maximum_initial_amount,
      ),
    ],
    other_goal_name: [requiredRule, (value: string) => maxLengthGoalNameRule(26, value)],
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  calculateMinimumAccumulatedAmount = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      const payload: CustomGoalMaximumInitialAmountCalculationDto = {
        monthly_required_amount: 0,
        fixed_time_adjusted: 1,
        desired_amount: parseCurrencyToNumber(this.inputs.desired_amount),
        interest_rate: !this.investor_goal_state.associated_product_id ? this.pocket_interest_rate
          : this.investor_goal_state.associated_product_interest_rate / 100,
      };
      const calculation = await this
        .calculate_maximum_initial_amount_query.internalExecute(payload);
      this.maximum_initial_amount = Math.trunc(
        parseFloat(calculation.maximum_initial_amount) * 100,
      ) / 100;
      if (this.view.$refs.form) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs.form.validate();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  delay = () => {
    this.investor_goal_state.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.calculateMinimumAccumulatedAmount();
    }, 2000);
  }

  loadState = () => {
    if (this.investor_goal_state.investor_goal.desired_amount !== 0) {
      this.inputs.initial_amount = this.investor_goal_state.investor_goal.initial_amount.toString();
      this.inputs.desired_amount = this.investor_goal_state.investor_goal.desired_amount.toString();
    }
  }

  setState = () => {
    this.investor_goal_state.investor_goal.desired_amount = parseCurrencyToNumber(
      this.inputs.desired_amount,
    );
    this.investor_goal_state.investor_goal.initial_amount = parseCurrencyToNumber(
      this.inputs.initial_amount,
    );
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    this.setState();
    this.view.$emit('nextStep');
  }

  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    this.loadState();
    await this.calculateMinimumAccumulatedAmount();
    this.investor_goal_state.is_loading = false;
  }
}
