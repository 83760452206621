
















































































import { Component, Vue } from 'vue-property-decorator';
import CustomDefinePlanViewModel
  from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/custom/custom-define-plan-view-model';

@Component({
  name: 'CustomDefinePlan',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class CustomDefinePlan extends Vue {
  view_model = Vue.observable(
    new CustomDefinePlanViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
